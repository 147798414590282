<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
			<div>
                <v-row justify="center" >
                    <v-col class="text-center " md="8" lg="7" xl="7">
                        <div class="font-weight-bold body-1">
                            Detalles de requisición
                        </div>
                    </v-col>
                </v-row> 
                <v-row justify="center">
                    <v-col cols="12" md="8" lg="7" xl="7" >

                    <details-item :detailsItems="items" data-cy="data-details-items"></details-item>


                    </v-col>
                    
                </v-row>

                 <v-row justify="center">
                    <v-col cols="12" md="8" lg="7" xl="7" class="pt-3 pb-0 font-weight-bold body-2 mb-0" >
                        <p class="mb-0 pb-0">Materias primas</p>
                    </v-col>
                </v-row>

                <v-row justify="center" >
                    <v-col class="py-3" cols="12" md="8" lg="7" xl="7">
                        <table-items data-cy="table-items" :props="tableProps"  :items="tableProps.items">
                            
                        </table-items>
                        
                    </v-col>
                    
                </v-row>

               
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="8" lg="7" xl="7" class="d-flex justify-end">
                    
					<primary-button data-cy="cancel-btn" :props="cancelBtnData"></primary-button>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import DetailsItem from '../../../components/DetailsItem.vue';
import Utils from '../../../helpers/Utils';
export default {
    components: {
        DetailsItem,
        
    },
	data(){
		return {
            id: this.$route.params.id,
            loading: false,
           
            items: [
               
                
            ],

            cancelBtnData: {
                text: "Regresar",
                icon: "",
                to: "Requisitions",
                block:false,
                click: () => {}
            },

            tableProps: {
				headers: [
                     {
						text: 'Materia prima',
						align: 'left break-words',
						value: 'commodity',
                        width:"40%",
						class: 'table-b-border black--text',
                        sortable: false,
					},
					
                    {
						text: 'Marca',
						align: 'center break-words',
						value: 'brand',
						sortable: false,
                        // width: "15%",
						class: 'table-b-border black--text'
					},
                    {
						text: 'Unidad',
						align: 'center break-words',
						value: 'unit',
						sortable: false,
                        // width: "15%",
						class: 'table-b-border black--text'
					},
                     {
						text: 'Color',
						align: 'center break-words',
						value: 'color',
						sortable: false,
                        // width: "15%",
						class: 'table-b-border black--text'
					},
                    {
						text: 'Tamaño',
						align: 'center break-words',
						value: 'size',
						sortable: false,
                        // width: "15%",
						class: 'table-b-border black--text'
					},
                    {
						text: 'Cantidad',
						align: 'center',
						value: 'quantity',
						sortable: false,
                        // width: "15%",
						class: 'table-b-border black--text'
					},

					
				],
				items: [],
				search: '',
				loading: false
			},
           
          
		}
    },
    mounted(){
        this.index();
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
    },
	methods: {
         onResize () {
            
            if(window.innerWidth < 960){
                this.cancelBtnData.block = true;
                
            }
            else{
                this.cancelBtnData.block = false;
                
            }
        },
		
        index(){
			//this.$store.state.overlay = true
			this.loading = true;
            this.$store.state.loading = true;
			this.$api.requisition.get(this.id)
			.then((response) => {
                console.log(response);
                

               
               
                this.items= [
                    { concepto: 'Folio', index: 'prospect', valor:response.data.folio},
                    { concepto: 'Fecha', index: 'description', valor:Utils.globalDateFormat(response.data.date??"")},
                    { concepto: 'Solicitante', index: 'project_name', valor:response.data.seller},
                    { concepto: 'Obra', index: 'validity', valor:response.data.work.name},
                    { concepto: 'Orden de venta', index: 'validity', valor:response.data.sale_order.folio},
                    { concepto: 'Proveedor', index: 'foreign_exchange', valor: `${response.data.supplier.name}`},
                    { concepto: 'Estatus', index: 'header', valor: response.data.status == "approved" ? "APROBADA":"Pendiente"},
                ];

                //products
                this.tableProps.items = response.data.commodities.map((item) => {
                    return {
                        ...item, 

                    };
                });
            
                
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				this.loading = false;
                this.$store.state.loading = false;
			})
		},
       
    },
}
</script>

<style>
    .v-skeleton-loader__text{
        margin-bottom: 0px;
    }
    .pre{
        white-space: pre-line;
    }
</style> 